.custom-list {
  border-radius: 20px 20px 0px 0px;

  .rdt_TableHeadRow {
    // background-color: black;
    padding: 20px 0px;
    // font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 25px;
    text-transform: uppercase;
  }
  // .dVgdrY {
  //   overflow: hidden !important;
  // }
  .rdt_Table {
    margin-left: 20px;
  }
}
